import { Menu } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { useState } from 'react';

import './SliderContent.css';

const SiderContent = props => {
  const collapsed = props.collapsed;
  const [activeKey] = useState('1');

  function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label
    };
  }

  function itemOnClick() {
    window.location = '/';
  }

  const items = [getItem('Dashboard', '1', <HomeOutlined />)];

  return (
    <section>
      <div className='top-spacing slider-container'>
        <h1 className='slider-heading'>{collapsed ? 'OM' : 'Orchestrator'}</h1>
        <h1 className='slider-text'>{collapsed ? 'UI' : 'Monitoring UI'}</h1>
      </div>
      <Menu theme='dark' selectedKeys={activeKey} mode='inline' items={items} onClick={itemOnClick} />
    </section>
  );
};

export default SiderContent;
