import { Spin } from 'antd';
import React, { useState, useEffect } from 'react';

export const AuthContext = React.createContext();

const AuthCheck = ({ children }) => {
  // const history = useHistory();
  const [token, setToken] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [userDetails, setUserDetails] = useState({
    id: '',
    email: ''
  });

  useEffect(() => {
    let userLocal = JSON.parse(sessionStorage.getItem('user'));
    if (userLocal?.id) {
      setToken(userLocal.id);
      setUserDetails({
        id: userLocal.id,

        email: userLocal.email
      });
      setIsAuthenticated(true);
      setIsLoaded(true);
    } else {
      setIsAuthenticated(false);
      setIsLoaded(true);
      setToken('');
      setUserDetails({
        id: '',

        email: ''
      });
    }
  }, []);

  const logoutHandler = () => {
    setIsAuthenticated(false);
    setToken('');
    setUserDetails({
      id: '',

      email: ''
    });

    sessionStorage.removeItem('user');
    // history.push('/');
  };

  return (
    <>
      {!isLoaded ? (
        <div className='loader'>
          <Spin size='large' />
        </div>
      ) : (
        <AuthContext.Provider
          value={{
            token,
            setToken,
            isAuthenticated,
            setIsAuthenticated,
            logoutHandler,
            setUserDetails,
            userDetails
          }}>
          {children}
        </AuthContext.Provider>
      )}
    </>
  );
};

export default AuthCheck;
