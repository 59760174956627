let BASE_SERVER_URL;
let KEY;
let GOOGLE_AUTH_ID;

if (process.env.REACT_APP_ENV === 'stage') {
  BASE_SERVER_URL = 'https://staging.async-fx.attentive.ai/api';
  KEY = '436236939443955C11494D448451F';
  GOOGLE_AUTH_ID = '674328735709-hpj4ch87bd62anqdpf6hpkm113khvm6p.apps.googleusercontent.com';
} else if (process.env.REACT_APP_ENV === 'prod') {
  BASE_SERVER_URL = 'https://async-fx-prod.attentive.ai/api';
  KEY = '1B25996595D7E3A8EF9997B87924A';
  GOOGLE_AUTH_ID = '674328735709-hpj4ch87bd62anqdpf6hpkm113khvm6p.apps.googleusercontent.com';
} else {
  BASE_SERVER_URL = 'https://staging.async-fx.attentive.ai/api';
  KEY = '436236939443955C11494D448451F';
  GOOGLE_AUTH_ID = '481344270506-hu8h4ntrina4l210m7m7hruviltskika.apps.googleusercontent.com';
}

export const BASE_URL = BASE_SERVER_URL;
export const API_KEY = KEY;

export const GET_REQUESTS = '/requests';
export const REQUEST = '/request/';

export const RESTART = '/restart';
export const RESUME = '/resume';
export const PAUSE = '/pause';

export const LOCAL_PATHNAMES = {
  IN_DETAIL: '/in-detail',
  RAW: '/raw'
};

export const GOOGLE_LOGIN_CLIENT_ID = GOOGLE_AUTH_ID;
