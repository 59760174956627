import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import AuthHOC from './Components/AuthHOC';
import Login from './LoginPage/login';

import Home from './MainPage/Home/Home';
import InDetail from './MainPage/RequestData/InDetail';
import InRaw from './MainPage/RequestData/InRaw';

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/login' element={<Login />} />

          <Route
            path='/'
            element={
              <AuthHOC>
                <Home />
              </AuthHOC>
            }
          />
          <Route
            path='/request/in-detail/:id'
            element={
              <AuthHOC>
                <InDetail />
              </AuthHOC>
            }
          />
          <Route
            path='/request/raw/:id'
            element={
              <AuthHOC>
                <InRaw />
              </AuthHOC>
            }
          />
          <Route path='*' element={<Navigate to='/' replace />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
