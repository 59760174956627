import React, { useEffect, useState } from 'react';
import jwt_decode from 'jwt-decode';
import { GOOGLE_LOGIN_CLIENT_ID } from '../Constants/URLs';
import { message, Spin } from 'antd';
import { postAPI } from '../Services/Api';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  // eslint-disable-next-line
  const navigate = useNavigate();
  // eslint-disable-next-line
  const location = useLocation();

  const handleLoginCallback = res => {
    setIsLoading(true);

    let userObj = jwt_decode(res.credential);

    let payload = {
      email_id: userObj.email,
      unique_id: userObj.sub
    };
    postAPI('/login', payload)
      .then(res => {
        let obj = {
          id: userObj.sub,
          email: userObj.email
        };
        sessionStorage.setItem('user', JSON.stringify(obj));
        setIsAuthenticated(true);

        window.location.replace('/');
        // if (location.state?.from) {
        //   navigate(location.state.from);
        // }
        // else {
        //   navigate('/');
        //   window.location.replace('/');
        // }
      })
      .catch(err => {
        console.log(err);
        setIsAuthenticated(false);
        message.error('Cannot log you in');
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    /* global google */
    google.accounts.id.initialize({
      client_id: GOOGLE_LOGIN_CLIENT_ID,
      callback: handleLoginCallback
    });

    google.accounts.id.renderButton(document.getElementById('signInDiv'), {
      theme: 'outline',
      size: 'large'
    });
  }, []);

  if (isAuthenticated) {
    return <Navigate to='/' replace />;
  }

  return (
    <>
      <Spin spinning={isLoading}>
        <div id='signInDiv' className='google_login_btn'></div>
      </Spin>
    </>
  );
};

export default Login;
