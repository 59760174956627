import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ReactJson from 'react-json-view';
import { Spin } from 'antd';

import { BASE_URL as BASEURL } from '../../Constants/URLs';
import LayoutComponent from '../Components/Layout';
import { getAPI } from '../../Services/Api';

const InRaw = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const getData = () => {
    setIsLoading(true);
    let url = BASEURL + '/v2/request/' + id + '/output';
    getAPI(url)
      .then(res => {
        setData(res);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <LayoutComponent id={id}>
      {isLoading ? (
        <Spin size='large' />
      ) : (
        <div>
          <ReactJson src={data} name={false} displayDataTypes={false} />
        </div>
      )}
    </LayoutComponent>
  );
};

export default InRaw;
