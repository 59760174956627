/**
 * @name getDateString
 * @function
 * @description Get date string from timestamp
 * @param {int} timestamp timestamp
 */
export const getDateTimeString = (timestamp, withTime = false, allNumbers = false, sendAsParams = false) => {
  const date = new Date(timestamp);
  const month = date.toLocaleString('default', { month: 'short' });
  const monthNum = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  let hours = date.getHours();
  let minutes = date.getMinutes();

  if (parseInt(hours) < 10) {
    hours = `0${hours}`;
  }
  if (parseInt(minutes) < 10) {
    minutes = `0${minutes}`;
  }

  let dateString = `${day}/${month}/${year}`;

  if (allNumbers) {
    dateString = `${day}/${monthNum}/${year}`;
  }

  if (withTime) {
    dateString += ` | ${hours}:${minutes}`;
  }

  if (sendAsParams) {
    dateString = dateString.replaceAll('/', '-');
  }
  return dateString;
};
