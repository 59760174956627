import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { AuthContext } from '../Stores/AuthContext';

const AuthHOC = ({ children }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const location = useLocation();

  return isAuthenticated ? children : <Navigate to='/login' replace state={{ from: location }} />;
};

export default AuthHOC;
