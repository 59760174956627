import { useEffect, useState } from 'react';
import { Row, Col, Button, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import { BASE_URL as BASEURL } from '../../Constants/URLs';
import LayoutComponent from '../Components/Layout';
import { getAPI } from '../../Services/Api';
import { getDateTimeString } from '../../Utils/HelperFunctions';

const InDetail = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const getData = () => {
    setIsLoading(true);
    let url = BASEURL + '/v2/request/' + id + '/output';
    getAPI(url)
      .then(res => {
        console.log(res);
        setData(res);
      })
      .finally(() => setIsLoading(false));
  };

  const onDownload = data => {
    // TODO: use output api here and make it download
    let url = BASEURL + '/v2/request/' + id + '/output';
    getAPI(url).then(res => {
      let data = res;
      console.log('check', data);
      var zip = new JSZip();
      var idZip = zip.folder(id);
      // let index = data?.pipeline_run?.current_step_index;
      // commented the below code as of now, because in v2 api we are not getting steps

      let output = data?.pipeline_run?.output;
      for (const [key, value] of Object.entries(output)) {
        idZip.file(key + '.json', JSON.stringify(value));
      }

      idZip.file('modified_parcel.json', JSON.stringify(data?.pipeline_run?.modified_parcel));

      idZip.generateAsync({ type: 'blob' }).then(
        function (blob) {
          saveAs(blob, id + '.zip');
        },
        function (err) {
          //handle error
        }
      );
    });
  };

  return (
    <LayoutComponent id={id}>
      {isLoading && <Spin className='loader' size='large' />}
      {data && !isLoading && (
        <div>
          <Row>
            <Col span={18}></Col>
            <Col span={3}>
              <Link to={'/request/raw/' + id}>
                <Button type='primary'>View Raw</Button>
              </Link>
            </Col>
            <Col span={3}>
              <Button
                type='primary'
                onClick={() => {
                  onDownload(data);
                }}>
                Download Zip
              </Button>
            </Col>
          </Row>
          <Row>
            <Col span={4}>
              <h6>Id: </h6>
            </Col>
            <Col span={20}>
              <h6>{data?.id}</h6>
            </Col>
          </Row>
          <hr></hr>
          <Row>
            <Col span={4}>
              <h6>Updated At: </h6>
            </Col>
            <Col span={20}>
              <h6>{getDateTimeString(data?.updated_at)}</h6>
            </Col>
          </Row>
          <hr></hr>
          <Row>
            <Col span={4}>
              <h6>Source Type: </h6>
            </Col>
            <Col span={20}>
              <h6>{data?.source_type}</h6>
            </Col>
          </Row>
          <hr></hr>
          <Row>
            <Col span={4}>
              <h6>Input -{'>'} Boundary Geojson: </h6>
            </Col>
            <Col span={20}>
              <pre className='preStyle'>{JSON.stringify(data?.input?.boundary_geojson, null, 0)}</pre>
            </Col>
          </Row>
          <hr></hr>
          <Row>
            <Col span={4}>
              <h6>Input -{'>'} Features: </h6>
            </Col>
            <Col span={20}>
              <pre className='preStyle'>{JSON.stringify(data?.input?.features, null, 0)}</pre>
            </Col>
          </Row>
          <hr></hr>
          <Row>
            <Col span={4}>
              <h6>Pipeline: </h6>
            </Col>
            <Col span={20}>
              <pre className='preStyle'>{JSON.stringify(data?.pipeline, null, 0)}</pre>
            </Col>
          </Row>
          <hr></hr>
          <Row>
            <Col span={4}>
              <h6>Status: </h6>
            </Col>
            <Col span={20}>
              <h6>{data?.pipeline_run?.status}</h6>
            </Col>
          </Row>
          <hr></hr>
          <Row>
            <Col span={4}>
              <h6>Bounds: </h6>
            </Col>
            <Col span={20}>
              <pre className='preStyle'>{JSON.stringify(data?.pipeline_run?.image, null, 0) ?? '-'}</pre>
            </Col>
          </Row>
          <hr></hr>
          <Row>
            <Col span={4}>
              <h6>Modified Parcel Area: </h6>
            </Col>
            <Col span={20}>
              <h6>
                {' '}
                {data?.pipeline_run?.modified_parcel?.features[0]?.properties &&
                  data?.pipeline_run?.modified_parcel?.features[0]?.properties?.area}
                {!data?.pipeline_run?.modified_parcel?.features[0]?.properties && data?.pipeline_run?.parcel_area} sqft.
              </h6>
            </Col>
          </Row>
          <hr></hr>
          <Row>
            <Col span={4}>
              <h6>Parcel: </h6>
            </Col>
            <Col span={20}>
              <pre className='preStyle'>{JSON.stringify(data?.pipeline_run?.modified_parcel, null, 0)}</pre>
            </Col>
          </Row>
          <hr></hr>
          {data?.pipeline_run?.layer === 'nearmap' && (
            <Row>
              <Col span={4}>
                <h6>Image: </h6>
              </Col>
              <Col span={20}>
                <img style={{ width: '20%' }} loading='lazy' src={data?.pipeline_run?.png_image_url} alt='png' />
              </Col>
            </Row>
          )}
          {data?.pipeline_run?.layer === 'nearmap' && <hr></hr>}
          <Row>
            <Col span={4}>
              <h6>Layer: </h6>
            </Col>
            <Col span={20}>
              <h6>{data?.pipeline_run?.layer || 'null'}</h6>
            </Col>
          </Row>
          <hr></hr>
        </div>
      )}
    </LayoutComponent>
  );
};

export default InDetail;
