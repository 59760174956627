import React, { useState } from 'react';
import { Layout, Breadcrumb } from 'antd';
import { useLocation } from 'react-router-dom';
import { HomeOutlined } from '@ant-design/icons';

import HeaderContent from './HeaderContent';
import FooterContent from './FooterContent';
import SiderContent from './SiderContent';

import './Layout.css';

import { LOCAL_PATHNAMES } from '../../Constants/URLs';

const { Header, Content, Footer, Sider } = Layout;

const LayoutComponent = ({ id, children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();

  return (
    <Layout className='layout'>
      <Sider collapsible collapsed={collapsed} onCollapse={value => setCollapsed(value)}>
        <SiderContent collapsed={collapsed} />
      </Sider>
      <Layout className='site-layout'>
        <Header className='header'>
          <HeaderContent />
        </Header>
        <Content className='content'>
          <Breadcrumb className='m-tb'>
            <Breadcrumb.Item href='/'>
              <HomeOutlined />
            </Breadcrumb.Item>

            {location.pathname.includes(LOCAL_PATHNAMES.IN_DETAIL) && (
              <>
                <Breadcrumb.Item>In-Detail</Breadcrumb.Item>
                <Breadcrumb.Item>{id}</Breadcrumb.Item>
              </>
            )}

            {location.pathname.includes(LOCAL_PATHNAMES.RAW) && (
              <>
                <Breadcrumb.Item>Raw</Breadcrumb.Item>
                <Breadcrumb.Item>{id}</Breadcrumb.Item>
              </>
            )}
          </Breadcrumb>
          <div className='site-layout-background'>{children}</div>
        </Content>
        <Footer className='footer'>
          <FooterContent />
        </Footer>
      </Layout>
    </Layout>
  );
};

export default LayoutComponent;
