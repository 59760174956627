import { Row } from 'antd';

const HeaderContent = () => {
  return (
    <Row>
      <div className='left'>
        <a href='https://attentive.ai/'>
          <img src='/img/logo-white.svg' alt='Attentive Logo' />
        </a>
      </div>
    </Row>
  );
};

export default HeaderContent;
