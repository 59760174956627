import React, { useEffect, useState } from 'react';
import { Table, Input, DatePicker, Button, Tooltip, message, Select, Space } from 'antd';
import { Link } from 'react-router-dom';
import { PlayCircleOutlined, RedoOutlined, PauseCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

import { BASE_URL as BASEURL, GET_REQUESTS, RESTART, PAUSE, RESUME, REQUEST } from '../../Constants/URLs';
import { getAPI, patchAPI, postAPI } from '../../Services/Api';
import {
  GET_STATUS,
  REQUEST_RESULTS,
  REQUEST_RESULTS_METADATA,
  REQUEST_STATUS,
  REQUEST_STATUS_VALUES
} from '../../Constants/Constants';
import { getDateTimeString } from '../../Utils/HelperFunctions';
import LayoutComponent from '../Components/Layout';

const { RangePicker } = DatePicker;
const { Option } = Select;

const Home = () => {
  const [searchValue, setSearchValue] = useState('');
  const [requestDetails, setRequestDetails] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  // eslint-disable-next-line
  const [pageDetails, setPageDetails] = useState({ page: 1, pageSize: 10, count: 0 });
  const [refreshComponent, setRefreshComponent] = useState(false);
  const [requestStatus, setRequestStatus] = useState(undefined);
  const [dateRange, setDateRange] = useState({ from_date: null, to_date: null });
  const [dateRangeChosen, setDateRangeChosen] = useState(false);
  // add params state var here

  useEffect(() => {
    getRequests();
    // eslint-disable-next-line
  }, [refreshComponent]);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'request_id',
      key: 'id',
      ellipsis: true
    },
    {
      title: 'Status',
      key: 'status',
      ellipsis: true,
      render: (text, record) => <p>{GET_STATUS[record.status]}</p>
    },
    {
      title: 'Actions',
      key: 'actions',
      ellipsis: true,
      render: (text, record) => {
        // only for failed show restart.
        // completed -> no action
        // in progress , pending -> pause/resume action
        if (record.status === 1) {
          return (
            <Tooltip title='Pause this request'>
              <Button
                type='primary'
                icon={<PauseCircleOutlined />}
                shape='circle'
                onClick={e => requestActionHandler(e, record, PAUSE)}></Button>
            </Tooltip>
          );
        } else if (record.status === 2) {
          return (
            <Tooltip title='Pause this request'>
              <Button
                type='primary'
                icon={<PauseCircleOutlined />}
                shape='circle'
                onClick={e => requestActionHandler(e, record, PAUSE)}></Button>
            </Tooltip>
          );
        } else if (record.status === 4) {
          return (
            <Button
              type='dashed'
              icon={<PlayCircleOutlined />}
              shape='circle'
              disabled
              // onClick={}
            ></Button>
          );
        } else if (record.status === 5) {
          return (
            <Tooltip title='Restart this request'>
              <Button
                type='primary'
                icon={<RedoOutlined />}
                shape='circle'
                onClick={e => requestActionHandler(e, record, RESTART)}></Button>
            </Tooltip>
          );
        } else if (record.status === 6) {
          return (
            <Tooltip title='Resume this request'>
              <Button
                type='primary'
                icon={<PlayCircleOutlined />}
                shape='circle'
                onClick={e => requestActionHandler(e, record, RESUME)}></Button>
            </Tooltip>
          );
        } else {
          return <Button type='dashed' icon={<PlayCircleOutlined />} shape='circle' disabled></Button>;
        }
      }
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      ellipsis: true,
      render: (text, record) => <p>{getDateTimeString(text, true)}</p>
    },
    {
      title: 'In Detail',
      key: 'indetail',
      ellipsis: true,
      render: (text, record) => {
        if (text.status === REQUEST_STATUS_VALUES.COMPLETED || text.status === REQUEST_STATUS_VALUES.POLING) {
          return <Link to={'request/in-detail/' + record.request_id}>View in detail</Link>;
        } else {
          return <p>Data not present {'😓'}</p>;
        }
      }
    },
    {
      title: 'Shareable Link',
      key: 'lens',
      ellipsis: true,
      render: (text, record) => {
        if (text.status === REQUEST_STATUS_VALUES.COMPLETED || text.status === REQUEST_STATUS_VALUES.POLING) {
          return (
            <a href={'https://lens.attentive.ai/shared-view?id=' + record.request_id} target='_blank' rel='noreferrer'>
              View in Lens
            </a>
          );
        } else {
          return <p>Link not generated {'👎'}</p>;
        }
      }
    }
  ];

  const getRequests = (pageNo = pageDetails.page, pageSize = pageDetails.pageSize) => {
    setTableLoading(true);

    let params = {
      page: pageNo,
      page_size: pageSize,
      status: requestStatus,
      search: searchValue === '' ? undefined : searchValue,
      from_date: dateRange.from_date,
      to_date: dateRange.to_date
    };

    getAPI(GET_REQUESTS, params)
      .then(res => {
        setRequestDetails(res[REQUEST_RESULTS]);
        setPageDetails(prevState => ({ ...prevState, count: res[REQUEST_RESULTS_METADATA]?.total_count }));
      })
      .finally(() => setTableLoading(false));
  };

  const onSearch = event => {
    if (event.keyCode === 13) {
      getRequests(pageDetails.page, pageDetails.pageSize, requestStatus, searchValue);
    }
  };

  const requestActionHandler = (event, requestData, action) => {
    setTableLoading(true);
    let payload = {};

    let url = BASEURL + REQUEST + requestData.request_id;
    if (action === RESTART) url = url + RESTART;
    else if (action === PAUSE) url = url + PAUSE;
    else if (action === RESUME) url = url + RESUME;

    if (action === PAUSE || action === RESUME) {
      patchAPI(url, payload)
        .then(() => {
          let messageText;
          if (action === PAUSE) messageText = `${requestData.request_id} paused successfully`;
          else if (action === RESUME) messageText = `${requestData.request_id} resumed successfully`;
          message.success(messageText, 1);
          setRefreshComponent(prevState => !prevState);
        })
        .finally(() => {
          setTableLoading(false);
        });
    } else {
      postAPI(url, payload)
        .then(() => {
          message.success(`${requestData.request_id} resumed successfully`, 1);
          setRefreshComponent(prevState => !prevState);
        })
        .finally(() => {
          setTableLoading(false);
        });
    }
  };

  const datePickerhandler = ([from, to]) => {
    let fromDate = getDateTimeString(from._d, false, true, true);
    let toDate = getDateTimeString(to._d, false, true, true);

    fromDate = fromDate.split('-').reverse().join('-');
    toDate = toDate.split('-').reverse().join('-');
    setDateRange({ from_date: fromDate, to_date: toDate });
    setDateRangeChosen(true);
    setRefreshComponent(prevState => !prevState);
  };

  return (
    <LayoutComponent>
      <div>
        <section className='home-section-container'>
          <div className='home-section-div'>
            <div style={{ width: '100%' }}>
              <Input
                placeholder='Input Id'
                style={{ width: '50%' }}
                value={searchValue}
                onChange={e => setSearchValue(e.target.value)}
                onKeyDown={onSearch}
              />
              {searchValue.length !== 0 && (
                <Button
                  icon={<CloseCircleOutlined />}
                  type='primary'
                  style={{ margin: '0 0.5rem' }}
                  shape='circle'
                  onClick={() => {
                    setSearchValue('');
                    setRefreshComponent(prevState => !prevState);
                  }}></Button>
              )}
              <Select
                style={{ width: '20%', margin: '0 1rem' }}
                placeholder='Filter Status by..'
                onChange={status => {
                  setRequestStatus(status);
                  setRefreshComponent(prevState => !prevState);
                  // getRequests(pageDetails.page, pageDetails.pageSize, status, searchValue);
                }}
                allowClear>
                {Object.keys(REQUEST_STATUS).map(status => (
                  <Option value={REQUEST_STATUS_VALUES[status]} key={REQUEST_STATUS_VALUES[status]}>
                    {REQUEST_STATUS[status]}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          <div>
            <Space>
              <RangePicker onChange={datePickerhandler} allowClear />
              {dateRangeChosen && (
                <Button
                  icon={<CloseCircleOutlined />}
                  onClick={() => {
                    setDateRange({ to_date: null, from_date: null });
                    setRefreshComponent(prevState => !prevState);
                    setDateRangeChosen(false);
                  }}></Button>
              )}
            </Space>
          </div>
        </section>
        <Table
          columns={columns}
          dataSource={requestDetails}
          loading={tableLoading}
          bordered
          pagination={{
            onChange: (page, size) => getRequests(page, size, requestStatus),
            showSizeChanger: true,
            onShowSizeChange: (current, size) => getRequests(current, size, requestStatus),
            total: pageDetails.count
          }}
        />
      </div>
    </LayoutComponent>
  );
};

export default Home;
