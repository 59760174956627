export const PENDING = 'PENDING';
export const POLING = 'POLING';
export const COMPLETED = 'COMPLETED';
export const FAILED = 'FAILED';
export const PAUSED = 'PAUSED';
export const IN_PROGRESS = 'IN-PROGRESS';
export const ALL = 'ALL';
export const REQUEST_RESULTS = 0;
export const REQUEST_RESULTS_METADATA = 1;

export const REQUEST_STATUS = {
  [PENDING]: 'Pending',
  [POLING]: 'Poling',
  [COMPLETED]: 'Completed',
  [FAILED]: 'Failed',
  [PAUSED]: 'Paused',
  [IN_PROGRESS]: 'In Progress'
};

export const REQUEST_STATUS_VALUES = {
  [PENDING]: 1,
  [POLING]: 3,
  [COMPLETED]: 4,
  [FAILED]: 5,
  [PAUSED]: 6,
  [IN_PROGRESS]: 2
};

export const GET_STATUS = {
  1: REQUEST_STATUS.PENDING,
  2: REQUEST_STATUS[IN_PROGRESS],
  3: REQUEST_STATUS.POLING,
  4: REQUEST_STATUS.COMPLETED,
  5: REQUEST_STATUS.FAILED,
  6: REQUEST_STATUS.PAUSED
};
